body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --logoPrimary: cyan;
  --logoSecondary: magenta;
  --textPrimary: black;
  --textSecondary: rgb(32, 32, 32);
  --primary-alpha: rgba(0, 255, 255, 0.5);
  --secondary-alpha: rgba(255, 0, 255, 0.5);

  --bgGreyText: rgb(47, 79, 79, 1);
  --bgGreyBorder: rgb(47, 79, 79, 0.4);
  --bgGrey: rgb(47, 79, 79, 0.2);

  --bgGreenText: rgba(0, 128, 0, 1);
  --bgGreenBorder: rgba(0, 128, 0.4);
  --bgGreen: rgba(0, 128, 0, 0.2);

  --bgRedText: rgba(255, 0, 0, 1);
  --bgRedBorder: rgba(255, 0, 0, 0.4);
  --bgRed: rgba(255, 0, 0, 0.2);

  --bgYellowText: rgb(128, 128, 0);
  --bgYellowBorder: rgba(200, 200, 0, 0.4);
  --bgYellow: rgba(200, 200, 0, 0.2);

  --bgBlueText: rgba(0, 128, 255, 1);
  --bgBlueBorder: rgba(0, 128, 255, 0.4);
  --bgBlue: rgba(0, 128, 255, 0.2);

  --bgPurpleText: rgba(234, 0, 255, 1);
  --bgPurpleBorder: rgba(234, 0, 255, 0.4);
  --bgPurple: rgba(234, 0, 255, 0.2);

  --bgMintText: rgb(0, 100, 100);
  --bgMintBorder: rgba(0, 255, 255, 0.4);
  --bgMint: rgba(0, 255, 255, 0.2);

  --bgNoteText: yellow;
  --bgNoteBorder: darkkhaki;
  --bgNote: khaki;
  --bgNoteGradient: linear-gradient(135deg, khaki 90%, darkgrey);

  --bgLeaveText: grey;
  --bgLeaveBorder: black;
  --bgLeave: rgb(47, 79, 79, 0.2);
  --bgLeaveGradient: linear-gradient(135deg, khaki 90%, darkgrey);
}

.box {
  box-shadow: 0 0 15px #3333;
}
